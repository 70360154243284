import { useCallback } from 'react';
import {
  Box,
  Button,
  FormControl,

  useMediaQuery,

  TextField,
  Unstable_Grid2 as Grid
} from '@mui/material';

import { styled } from '@mui/material/styles';


const LeanworksTextField = styled(TextField)({
  // '& input:valid + fieldset': {
  //   borderColor: '#E0E3E7',
  //   borderWidth: 1,
  // },
  // '& input:invalid + fieldset': {
  //   borderColor: 'red',
  //   borderWidth: 1,
  // },
  // '& input:valid:focus + fieldset': {
  //   borderLeftWidth: 4,
  //   padding: '4px !important', // override inline-style
  // },
  '& label': {
    color: '#142F43',
    // backgroundColor: 'white',
    borderRadius: '25px',
    paddingLeft: '4px',
    paddingRight: '4px',
    marginLeft: '0px',
    fontSize: '16px',
  },
  '& label.Mui-focused': {
    paddingLeft: '4px',
    paddingRight: '4px',
    marginLeft: '0px',
    color: 'primary.main',
    backgroundColor: 'white'
  },
  '& .MuiOutlinedInput-root': {
    '& input': {
      color: '#000000',
      fontSize: '16px',
      marginLeft: '4px'
    },
    '& textarea': {
      color: '#000000',
      fontSize: '16px',
      lineHeight: '24px',
      marginLeft: '4px'
    },
    '& fieldset': {
      borderRadius: '25px',
      border: '1px solid #EFF0F7',
      boxShadow: '0px 2px 6px rgba(19, 18, 66, 0.07)'
    },
    '&:hover fieldset': {
      borderColor: '#142F43',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    }
  }
});


export const LeanworksContactForm = () => {

  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const xlUp = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    console.log(event.target)
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          xs={12}
        // sm={6}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              mt: 0
            }}
          >
            <FormControl fullWidth
              sx={{
                opacity: '0.75'
              }}
            >
              <LeanworksTextField
                id="name"
                label="Name"
                // required
                variant="outlined"
                type="text"
                name="name"
              />
            </FormControl>
          </Box>
        </Grid>

        <Grid
          xs={12}
        // sm={6}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              mt: xlUp ? 1 : 0
            }}
          >
            <FormControl fullWidth
              sx={{
                opacity: '0.75',
                fontSize: '24px',
              }}
            >
              <LeanworksTextField
                id="email"
                label="Email"
                // required
                variant="outlined"
                type="email"
                name="email"
              />
            </FormControl>
          </Box>
        </Grid>

        <Grid
          xs={12}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              mt: xlUp ? 1 : 0
            }}
          >
            <FormControl fullWidth
              sx={{
                opacity: '0.75'
              }}
            >
              <LeanworksTextField
                id="message"
                label="Message"
                // required
                variant="outlined"
                type="text"
                name="message"
                multiline
                rows={xlUp ? 4 : 3}
              />
            </FormControl>
          </Box>
        </Grid>

        <Grid xs={5}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              mt: xlUp ? '26px' : '0px'
            }}
          >
            <Button
              fullWidth
              disableElevation
              variant="contained"
              type="submit"
              sx={{
                borderRadius: '25px',
                width: xlUp ? '210px' : '193px',
                height: xlUp ? '50px' : '48px',
                gap: '10px',
                mr: '20px',
                px: '30px',
                py: '12px',
                borderWidth: '2px',
                fontFamily: 'Poppins',
                fontWeight: 400,
                fontSize: xlUp ? '18px' : '16px',
                lineHeight: xlUp ? '26px' : '24px'
              }}
            >
              Let&apos;s Talk
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* <Typography
        color="text.secondary"
        sx={{ mt: 2 }}
        variant="body2"
      >
        By submitting this, you agree to the
        {' '}
        <Link
          color="text.primary"
          href="#"
          underline="always"
          variant="subtitle2"
        >
          Privacy Policy
        </Link>
        {' '}
        and
        {' '}
        <Link
          color="text.primary"
          href="#"
          underline="always"
          variant="subtitle2"
        >
          Cookie Policy
        </Link>
        .
      </Typography> */}
    </form>
  );
};
