const ToDownArrowIcon = (props) => (
    <svg width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="26"
            cy="26"
            r="26"
            fill="#07BFC0"
            fillOpacity="0.2" />
        <circle cx="25.5"
            cy="25.5"
            r="21.5"
            fill="#07BFC0"
            fillOpacity="0.4" />
        <circle cx="26"
            cy="26"
            r="14"
            fill="#07BFC0" />
        <path d="M32.7092 23.3299C32.6163 23.2254 32.5057 23.1424 32.3839 23.0858C32.262 23.0292 32.1313 23 31.9993 23C31.8673 23 31.7366 23.0292 31.6148 23.0858C31.4929 23.1424 31.3824 23.2254 31.2894 23.3299L26.7099 28.4378C26.617 28.5424 26.5064 28.6253 26.3845 28.6819C26.2627 28.7386 26.132 28.7677 26 28.7677C25.868 28.7677 25.7373 28.7386 25.6155 28.6819C25.4936 28.6253 25.383 28.5424 25.2901 28.4378L20.7106 23.3299C20.6176 23.2254 20.5071 23.1424 20.3852 23.0858C20.2634 23.0292 20.1327 23 20.0007 23C19.8687 23 19.738 23.0292 19.6161 23.0858C19.4943 23.1424 19.3837 23.2254 19.2908 23.3299C19.1045 23.5389 19 23.8215 19 24.1162C19 24.4108 19.1045 24.6935 19.2908 24.9024L23.8802 30.0215C24.4427 30.6481 25.2051 31 26 31C26.7949 31 27.5573 30.6481 28.1198 30.0215L32.7092 24.9024C32.8955 24.6935 33 24.4108 33 24.1162C33 23.8215 32.8955 23.5389 32.7092 23.3299Z"
            fill="white" />
    </svg>

);

export default ToDownArrowIcon;
