
import { Box, Container, Stack, Typography, Button, useMediaQuery, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LeanworksContactForm } from '../contact/leanworks-contact-form';



export const HomeContactUs = () => {
  const theme = useTheme();
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const xlUp = useMediaQuery((theme) => theme.breakpoints.up('xl'));


  return (
    <Box >

      <Grid container
        spacing={0}
        sx={{

        }}
      >
        <Grid item
          xs={2}>
          <Box
            ml="10px"
          >
            <Box sx={{
              // backgroundColor: 'pink',
              backgroundRepeat: 'no-repeat',
              transform: xlUp ? 'translate(0px, -40px)' : 'translate(0px, -40px)',
              backgroundPosition: 'top left',
              backgroundImage: xlUp ? `url("/assets/bg-contact-us-left-xl.svg")` : mdUp ? `url("/assets/bg-contact-us-left-md.svg")` : `url("/assets/bg-contact-us-left-md.svg")`,
              margin: 'auto',
              width: '100%',
              height: xlUp ? '617px' : '489px'
            }} />
          </Box>
        </Grid>
        <Grid item
          xs={8}>

          <Box sx={{
            // backgroundColor: 'lightgreen',
            backgroundRepeat: 'no-repeat',
            transform: xlUp ? 'translate(0px, -40px)' : 'translate(0px, -41px)',
            backgroundPosition: 'top center',
            backgroundImage: xlUp ? `url("/assets/bg-contact-us-xl.svg")` : mdUp ? `url("/assets/bg-contact-us-md.svg")` : `url("/assets/bg-contact-us-md.svg")`,
            margin: 'auto',
            width: '100%',
            height: xlUp ? '617px' : '489px'
          }} />

        </Grid>
        <Grid item
          xs={2}>
          <Box
            mr="10px"
          >
            <Box sx={{
              // backgroundColor: 'pink',
              backgroundRepeat: 'no-repeat',
              transform: xlUp ? 'translate(0px, -37px)' : 'translate(0px, -41px)',
              backgroundPosition: 'top right',
              backgroundImage: xlUp ? `url("/assets/bg-contact-us-right-xl.svg")` : mdUp ? `url("/assets/bg-contact-us-right-md.svg")` : `url("/assets/bg-contact-us-right-md.svg")`,
              margin: 'auto',
              width: '100%',
              height: xlUp ? '617px' : '489px'
            }} />
          </Box>


        </Grid>

      </Grid>


      <Grid container
        spacing={0}
        sx={{
          transform: xlUp ? 'translate(0px, -530px)' : 'translate(0px, -439px)',
          height: 0
        }}
      >

        <Grid item
          xs={5}>


          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            pl={xlUp ? '18.5%' : '17%'}
          >

            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing='20px'//16px
              pl='20px'
            >
              <Typography
                color="#00BDBF"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '22px',
                  letterSpacing: '2px',
                  textTransform: 'uppercase'
                }}
              >
                get in touch
              </Typography>
              <Typography
                color="#142F43"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: xlUp ? `50px` : '36px',
                  fontWeight: '400',
                  lineHeight: xlUp ? `60px` : '46px'
                }}
              >
                Contact Us
              </Typography>


            </Stack>
            <Box
              sx={{
                backgroundRepeat: 'no-repeat',
                transform: 'translate(0px, 0px)',
                backgroundPosition: 'bottom left',

                backgroundImage: xlUp ? `url("/assets/bg-contact-us-shape-xl.svg")` : mdUp ? `url("/assets/bg-contact-us-shape-md.svg")` : `url("/assets/bg-contact-us-shape-md.svg")`,
                pt: xlUp ? '300px' : '247px',

                width: '100%',
                height: xlUp ? `230px` : mdUp ? `157px` : `157px`
              }}
            >


            </Box>
          </Stack>


        </Grid>
        <Grid item
          xs={7}>

          <Box
            sx={{

              pr: xlUp ? '25%' : lgUp ? '14%' : '14%',
              pl: xlUp ? '12%' : lgUp ? '7%' : '5%',
            }}
          >

            <LeanworksContactForm />

          </Box>
        </Grid>
      </Grid>

    </Box>

  );
};
