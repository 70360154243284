import { useTheme } from '@mui/material/styles';

export const Logo = () => {
  const theme = useTheme();
  const fillColor = theme.palette.text.primary;

  return (
    <svg
      viewBox="0 0 216 45"
      fill="none"
      width="100%"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_219:323)">
        <path d="M14.3396 31.7311V44.9999H23.9549C31.0862 44.9999 37.3256 40.2046 37.8409 33.8325C37.8687 33.5023 37.8841 33.1691 37.8841 32.8358V31.7372L14.3396 31.7311Z"
          fill="url(#paint0_linear_219:323)" />
        <path d="M0 0C3.98683 2.90681 6.62518 4.44044 6.62518 13.3213V31.4195C6.62518 37.4059 9.76034 42.5591 14.2872 44.9969C14.3859 44.9969 14.4816 44.9969 14.5772 44.9969V16.2837C14.5772 7.28862 8.05081 0 0 0Z M6.62518 31.4195V13.3213C6.62518 4.44044 3.98683 2.90681 0 0V28.7163C0 34.4312 2.64143 39.4487 6.62518 42.3524C8.84743 43.9978 11.523 44.918 14.2872 44.9877C9.76034 42.5591 6.62518 37.4059 6.62518 31.4195Z"
          fill="url(#paint1_linear_219:323)" />
        <path d="M0 0C3.98683 2.90681 6.62518 4.44044 6.62518 13.3213V31.4195C6.62518 37.4059 9.76034 42.5591 14.2872 44.9969C14.3859 44.9969 14.4816 44.9969 14.5772 44.9969V16.2837C14.5772 7.28862 8.05081 0 0 0Z"
          fill="url(#paint1_linear_219:323)" />
        <path d="M6.62518 31.4195V13.3213C6.62518 4.44044 3.98683 2.90681 0 0V28.7163C0 34.4312 2.64143 39.4487 6.62518 42.3524C8.84743 43.9978 11.523 44.918 14.2872 44.9877C9.76034 42.5591 6.62518 37.4059 6.62518 31.4195Z"
          fill="url(#paint2_linear_219:323)" />
        <path d="M57.2444 9.42712H52.23V36.1593H57.2444V9.42712Z"
          fill={fillColor} />
        <path d="M75.9103 18.4283C74.1823 16.6725 71.8988 15.7807 69.1617 15.7807C67.7852 15.7615 66.4188 16.0188 65.1435 16.5373C63.8682 17.0559 62.71 17.8251 61.7374 18.7994C60.7648 19.7737 59.9976 20.9333 59.4813 22.2096C58.9651 23.4858 58.7102 24.8526 58.7318 26.2291C58.702 27.6145 58.961 28.9909 59.4923 30.2707C60.0236 31.5506 60.8155 32.7057 61.8176 33.6628C63.8789 35.6562 66.5141 36.6653 69.6524 36.6653C72.5129 36.6701 75.2833 35.6647 77.4748 33.8263L75.4506 30.5029C73.83 31.7387 71.8538 32.4184 69.8159 32.4408C68.4284 32.4857 67.0612 32.0986 65.9032 31.333C64.8309 30.5682 64.0653 29.4472 63.7431 28.1701L63.6444 27.806H78.2031C78.4049 26.8939 78.5084 25.9629 78.5117 25.0288C78.5448 23.8101 78.3315 22.5973 77.8845 21.4631C77.4375 20.3289 76.766 19.2966 75.9103 18.4283ZM63.6629 24.4363L63.7554 24.0752C64.0453 22.8429 64.7158 21.7331 65.6717 20.9031C66.5891 20.1216 67.7593 19.7004 68.9643 19.7181C70.1714 19.6906 71.3454 20.1142 72.2568 20.9061C72.7138 21.3103 73.0779 21.8086 73.324 22.3668C73.5701 22.9251 73.6924 23.53 73.6824 24.1401V24.4486L63.6629 24.4363Z"
          fill={fillColor} />
        <path d="M96.585 36.1561V24.2696C96.585 21.5233 95.8167 19.4003 94.2954 17.9592C92.7741 16.5181 90.685 15.7806 88.096 15.7806C84.7017 15.7806 81.8134 17.1044 79.5299 19.7181L82.0664 22.7237C83.8377 20.9493 85.6552 20.0483 87.4758 20.0483C88.7903 20.0483 89.8117 20.397 90.5122 21.082C91.2127 21.7671 91.5737 22.7329 91.5737 23.9395V25.1398H86.1458C83.8469 25.1398 82.0047 25.6552 80.6686 26.6827C79.3324 27.7103 78.6875 29.0433 78.6875 30.8146C78.6651 31.6031 78.8153 32.387 79.1277 33.1114C79.44 33.8358 79.9069 34.4832 80.4958 35.0082C81.7054 36.1005 83.381 36.6529 85.4793 36.6529C87.9788 36.6529 90.0123 35.8475 91.5213 34.2583L92.0212 33.7337V36.1561H96.585ZM91.5706 28.7841C91.5867 29.3716 91.4756 29.9557 91.245 30.4963C91.0144 31.0368 90.6697 31.5212 90.2345 31.9162C89.3456 32.7263 88.1782 33.1619 86.9759 33.132C86.1567 33.1679 85.3479 32.9373 84.6708 32.4747C84.3773 32.265 84.1394 31.9866 83.9781 31.6639C83.8167 31.3412 83.7367 30.984 83.7451 30.6233C83.7451 29.5463 84.2882 28.2626 86.8895 28.2626H91.5675L91.5706 28.7841Z"
          fill={fillColor} />
        <path d="M107.694 20.215C108.243 20.1951 108.79 20.2902 109.3 20.4942C109.811 20.6983 110.272 21.0068 110.656 21.3999C111.04 21.8136 111.338 22.2994 111.533 22.8291C111.728 23.3588 111.816 23.9218 111.792 24.4857V36.1623H116.766V23.7328C116.766 21.3351 116.093 19.388 114.77 17.9439C113.446 16.4997 111.631 15.7838 109.351 15.7838C106.984 15.7838 105.031 16.5367 103.55 18.0179L103.047 18.5147V16.2374H98.489V36.1562H103.5V24.4796C103.478 23.9117 103.571 23.3451 103.775 22.8145C103.978 22.2839 104.288 21.8005 104.685 21.3938C105.08 21.0027 105.551 20.6965 106.068 20.4938C106.585 20.2911 107.139 20.1962 107.694 20.215Z"
          fill={fillColor} />
        <path d="M143.89 16.2373L138.953 28.0837L133.081 15.7806H131.584L125.838 28.346L120.772 16.2373H115.532L124.795 36.6529H126.249L132.309 24.3653L138.41 36.6529H139.866L149.13 16.2373H143.89Z"
          fill={fillColor} />
        <path d="M157.622 15.7807C156.221 15.7525 154.829 16.0038 153.527 16.5199C152.225 17.036 151.038 17.8064 150.037 18.7862C149.034 19.7344 148.24 20.8815 147.706 22.1541C147.172 23.4268 146.91 24.7968 146.936 26.1767C146.908 27.5619 147.167 28.9378 147.698 30.2174C148.23 31.497 149.021 32.6524 150.022 33.6104C151.019 34.5893 152.201 35.3591 153.5 35.8747C154.799 36.3903 156.188 36.6413 157.585 36.6128C158.993 36.6379 160.392 36.3836 161.702 35.8645C163.011 35.3455 164.204 34.572 165.213 33.5888C166.224 32.6415 167.026 31.4933 167.567 30.2179C168.108 28.9425 168.376 27.5681 168.354 26.1829C168.377 24.7997 168.11 23.427 167.569 22.1539C167.028 20.8808 166.225 19.7355 165.213 18.7924C164.208 17.8152 163.021 17.0461 161.718 16.5292C160.415 16.0124 159.023 15.758 157.622 15.7807ZM161.618 30.3919C160.555 31.456 159.115 32.0558 157.611 32.0598C156.107 32.0639 154.663 31.4719 153.595 30.4135C153.073 29.8528 152.666 29.1947 152.398 28.4767C152.13 27.7587 152.006 26.995 152.034 26.2292C151.983 24.6674 152.546 23.1479 153.601 21.9955C154.123 21.4597 154.748 21.0344 155.438 20.7446C156.128 20.4548 156.869 20.3066 157.617 20.3086C158.365 20.3106 159.106 20.4629 159.794 20.7563C160.482 21.0498 161.105 21.4785 161.624 22.0171C162.684 23.1592 163.253 24.6716 163.21 26.2292C163.232 26.9937 163.102 27.7549 162.829 28.4693C162.556 29.1836 162.144 29.837 161.618 30.3919Z"
          fill={fillColor} />
        <path d="M186.804 24.9702L194.96 36.1562H200.622L192.497 25.0041L199.943 16.2374H194.077L186.776 24.9332L186.804 24.9702Z"
          fill={fillColor} />
        <path d="M186.662 9.42712H181.648V36.1593H186.662V9.42712Z"
          fill={fillColor} />
        <path d="M174.868 18.6103L174.347 19.2769V16.2374H169.795V36.1561H174.807V26.2291C174.807 24.3777 175.34 22.9705 176.393 22.0201C177.445 21.0697 178.753 20.6346 180.404 20.6007V15.9936H180.256C179.214 15.9669 178.181 16.1907 177.244 16.646C176.306 17.1013 175.492 17.775 174.868 18.6103Z"
          fill={fillColor} />
        <path d="M215.104 18.743L212.358 22.0942C211.012 20.829 209.518 20.1964 207.874 20.1964C206.444 20.1964 205.73 20.6675 205.732 21.6097C205.731 21.7931 205.772 21.9742 205.853 22.1391C205.933 22.3039 206.05 22.448 206.195 22.5601C206.698 22.9152 207.25 23.1939 207.834 23.3871L209.772 24.1956C211.714 24.9773 213.134 25.8187 214.033 26.7198C214.932 27.6208 215.384 28.8129 215.388 30.2962C215.388 32.2896 214.654 33.852 213.188 34.9835C211.721 36.1149 209.855 36.6807 207.59 36.6807C204.035 36.6807 201.274 35.3877 199.308 32.8018L202.057 29.4877C203.808 31.2919 205.679 32.1939 207.67 32.1939C208.304 32.2175 208.932 32.0716 209.491 31.7712C209.715 31.6543 209.902 31.4765 210.031 31.2582C210.159 31.0399 210.223 30.7899 210.216 30.5369C210.226 30.3464 210.19 30.1563 210.109 29.9835C210.028 29.8107 209.906 29.6605 209.753 29.5463C209.251 29.2159 208.717 28.9377 208.158 28.7163L206.016 27.8276C204.183 27.0746 202.816 26.2404 201.915 25.325C201.014 24.4095 200.568 23.2123 200.576 21.7331C200.576 19.8467 201.256 18.3717 202.616 17.3081C203.975 16.2446 205.747 15.7128 207.929 15.7128C210.91 15.7128 213.302 16.7228 215.104 18.743Z"
          fill={fillColor} />
      </g>
      <defs>
        <linearGradient id="paint0_linear_219:323"
          x1="14.3396"
          y1="38.3655"
          x2="37.8872"
          y2="38.3655"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#02778F" />
          <stop offset="0.12"
            stopColor="#018B9D" />
          <stop offset="0.29"
            stopColor="#01A1AC" />
          <stop offset="0.48"
            stopColor="#00B1B7" />
          <stop offset="0.7"
            stopColor="#00BABD" />
          <stop offset="1"
            stopColor="#00BDBF" />
        </linearGradient>
        <linearGradient id="paint1_linear_219:323"
          x1="7.28863"
          y1="-90"
          x2="7.28863"
          y2="45"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#02EA8F" />
          <stop offset="1"
            stopColor="#00BDBF" />
        </linearGradient>
        <linearGradient id="paint2_linear_219:323"
          x1="7.14359"
          y1="-2.48097"
          x2="7.14359"
          y2="47.4655"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C0FFDE" />
          <stop offset="1"
            stopColor="#00BDBF" />
        </linearGradient>
        <clipPath id="clip0_219:323">
          <rect width="215.388"
            height="45"
            fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};
