import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, ButtonBase, Paper, Portal, Typography, useMediaQuery, Stack } from '@mui/material';
import { css, keyframes } from "@emotion/react";
import { RouterLink } from 'src/components/router-link';
import { useWindowScroll } from 'src/hooks/use-window-scroll';
import MenuDownIcon from 'src/icons/leanworks/menu-down-icon';


const TOP_NAV_SPACE = 0;
const OFFSET = 16;
const delay = 20;

const drawLineEffect = keyframes`
  0% {
    right: 100%;
    height: 0px;
  }
  100% {
    right: 0;
    height: 6px;
  }
`;

const eraseLineEffect = keyframes`
  0% {
    right: 0;
    height: 6px;
  }
  100% {
    right: 0;
    height: 0px;
  }
`;


export const TopNavItem = (props) => {
  const { active, external, path, popover, title } = props;
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);

  const [portalTop, setPortalTop] = useState(0);

  const xlUp = useMediaQuery((theme) => theme.breakpoints.up('xl'));
  // const theme = useTheme();

  const TOP_NAV_HEIGHT = xlUp ? 140 : 108;
  const mediaQueryOffset = xlUp ? 900 : 600;

  const handleWindowScroll = useCallback(() => {
    if (mediaQueryOffset >= window.scrollY) {
      let value = (1 - (mediaQueryOffset - window.scrollY) / mediaQueryOffset) < 0 ? 0 : (1 - (mediaQueryOffset - window.scrollY) / mediaQueryOffset).toFixed(2)
      //between 0 and 1
      // console.log(value)
      setPortalTop(value);
    } else {
      setPortalTop(1);
    }

  }, [mediaQueryOffset]);

  useWindowScroll({
    handler: handleWindowScroll,
    delay
  });

  const handleMouseEnter = useCallback(() => {
    setOpen(true);
    setClose(false);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setOpen(false);
    setClose(true);
  }, []);

  // With mega-menu

  if (popover) {
    return (
      <>
        <Box
          component="li"
          sx={{
            // display: 'flex',
            position: 'relative',
            alignItems: 'center',
            height: '40%',//change
            // backgroundColor: 'pink'

          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >

          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0}

          >

            <ButtonBase
              disableRipple
              sx={{

                alignItems: 'center',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'center',
                mx: '6px',
                minWidth: xlUp ? '118px' : '106px',
                pb: '8px',
                textAlign: 'left',

                ...(active && {
                  color: 'primary.main'
                })
              }}
            >

              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{
                  pt: xlUp ? '15px' : '10px'
                }}
              >


                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 400,
                    fontSize: xlUp ? '18px' : '16px',
                    lineHeight: xlUp ? '26px' : '24px',
                    marginBottom: '3px'
                  }}
                >
                  {title}
                </Typography>
                <Box
                  sx={{
                    marginLeft: '10px',
                    width: '100%',
                    marginBottom: '3px'
                  }}
                >
                  <MenuDownIcon
                    currentcolor="text.primary"
                  />
                </Box>
              </Stack>
            </ButtonBase>
            <Box sx={{

              backgroundRepeat: 'no-repeat',
              backgroundPosition: '0% 50%',
              transform: xlUp ? 'translate(6px, 22px)' : 'translate(6px, 18px)',
              backgroundImage: xlUp ? `url("/assets/bg-menu-underline-xl.svg")` : `url("/assets/bg-menu-underline-md.svg")`,
              height: xlUp ? '6px' : '6px',
              position: 'absolute',
              left: 0,
              ...(open && {
                animation: (theme) => `${drawLineEffect} 500ms ${theme.transitions.easing.easeInOut} forwards`
              }),
              ...(close && {
                animation: (theme) => `${eraseLineEffect} 250ms ${theme.transitions.easing.easeInOut} forwards`
              })
            }}>
            </Box>

          </Stack>
        </Box>
        {open && (
          <Portal>
            <Box
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              sx={{
                marginRight: '25px',
                left: '35%',
                position: 'fixed',
                pt: OFFSET + 'px',
                right: '35%',
                top: xlUp ? 50 - (portalTop * 50) + TOP_NAV_HEIGHT + TOP_NAV_SPACE - 40 : 33 - (portalTop * 33) + TOP_NAV_HEIGHT + TOP_NAV_SPACE - 27,
                zIndex: (theme) => theme.zIndex.appBar + 100,
                borderRadius: '0px 0px 26px 26px',
                // backgroundColor: 'orange'
              }}
            >
              <Paper
                elevation={16}
                sx={{
                  background: '#FFFFFF',
                  border: '1px solid #EFF0F7',
                  boxShadow: '0px 2px 6px rgba(19, 18, 66, 0.07)',
                  borderRadius: '26px',
                  width: xlUp ? '340px' : '312px',
                  mx: 'auto',
                }}
              >
                {popover}
              </Paper>
            </Box>
          </Portal>
        )}
      </>
    );
  }

  // Simple

  const linkProps = path
    ? external
      ? {
        component: 'a',
        href: path,
        target: '_blank'
      }
      : {
        component: RouterLink,
        href: path
      }
    : {};

  return (
    <Box
      component="li"
      sx={{
        position: 'relative',
        alignItems: 'center',
        height: '40%',//change
        // backgroundColor: 'purple'
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
      >


        <ButtonBase
          disableRipple
          sx={{
            alignItems: 'center',
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'center',
            mx: '6px',
            minWidth: xlUp ? '118px' : '106px',
            pb: '8px',
            textAlign: 'left',

            ...(active && {
              color: 'primary.main'
            })
          }}
          {...linkProps}>

          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{
              pt: xlUp ? '15px' : '10px'
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 400,
                fontSize: xlUp ? '18px' : '16px',
                lineHeight: xlUp ? '26px' : '24px',
                marginBottom: '3px'
              }}
            >
              {title}
            </Typography>
          </Stack>
        </ButtonBase>


        <Box sx={{
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '0% 50%',
          transform: xlUp ? 'translate(6px, 22px)' : 'translate(6px, 18px)',
          backgroundImage: xlUp ? `url("/assets/bg-menu-underline-xl.svg")` : `url("/assets/bg-menu-underline-md.svg")`,
          // height: xlUp ? '6px' : '6px',
          position: 'absolute',
          left: 0,
          ...(open && {
            animation: (theme) => `${drawLineEffect} 500ms ${theme.transitions.easing.easeInOut} forwards`
          }),
          ...(close && {
            animation: (theme) => `${eraseLineEffect} 250ms ${theme.transitions.easing.easeInOut} forwards`
          })
        }}>
        </Box>

      </Stack>

    </Box>
  );
};

TopNavItem.propTypes = {
  active: PropTypes.bool,
  external: PropTypes.bool,
  path: PropTypes.string,
  popover: PropTypes.any,
  title: PropTypes.string.isRequired
};
