import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';
import {
  Box,
  Button,
  ButtonBase,
  Container,
  Stack,
  SvgIcon,
  useMediaQuery
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Logo } from 'src/components/logo';
import { RouterLink } from 'src/components/router-link';
import { usePathname } from 'src/hooks/use-pathname';
import { useWindowScroll } from 'src/hooks/use-window-scroll';
import { paths } from 'src/paths';
import { TopNavItem } from './top-nav-item';
import { LeanworksServicesPopover } from './leanworks-services-popover';


const items = [
  {
    title: 'Who We Are',
    path: '#',
    // path: paths.components.index
  },
  // {
  //   title: 'Services',
  //   popover: <LeanworksServicesPopover />
  // },
  {
    title: 'About Us',
    path: '#',
    // path: paths.docs,
    // external: true
  }
];


export const TopNav = (props) => {
  const { onMobileNavOpen } = props;
  const pathname = usePathname();
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const xlUp = useMediaQuery((theme) => theme.breakpoints.up('xl'));
  const [elevate, setElevate] = useState(false);
  const [darknessValue, setDarknessValue] = useState(0);

  const offset = 16;
  const darkOffset = xlUp ? 900 : 600;
  const delay = 20;

  const TOP_NAV_HEIGHT = xlUp ? 140 : lgUp ? 108 : mdUp ? 88 : 74;

  const handleWindowScroll = useCallback(() => {
    if (window.scrollY > offset) {
      setElevate(true);
    } else {
      setElevate(false);
    }

    if (darkOffset + offset >= window.scrollY) {
      //between 0 and 1
      let value = (1 - (darkOffset + offset - window.scrollY) / darkOffset) < 0 ? 0 : (1 - (darkOffset + offset - window.scrollY) / darkOffset).toFixed(2)
      // console.log(value)
      setDarknessValue(value);

    } else {
      setDarknessValue(1)
    }

  }, [darkOffset]);

  useWindowScroll({
    handler: handleWindowScroll,
    delay
  });

  return (

    <Box
      component="header"
      mx={xlUp ? "50px" : mdUp ? "30px" : "15px"}
      sx={{
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
        pt: 1,//2 default
        zIndex: (theme) => theme.zIndex.appBar,
      }}
    >
      <Container
        // maxWidth={xlUp ? "xl" : "lg"}
        maxWidth={false}
        sx={{
          backdropFilter: elevate ? `blur(${(darknessValue * 120)}px)` : '',
          backgroundColor: 'transparent',
          borderRadius: xlUp ? "80px" : mdUp ? "50px" : smUp ? "40px" : "30px",
          boxShadow: 'none',
          marginTop: xlUp ? `${40 - (darknessValue * 40)}px` : lgUp ? `${25 - (darknessValue * 25)}px` : mdUp ? `${30 - (darknessValue * 30)}px` : `${10 - (darknessValue * 10)}px`,
          // transition: (theme) => theme.transitions.create('box-shadow, background-color, margin-top, backdrop-filter', {
          //   easing: theme.transitions.easing.easeOut,
          //   duration: 100
          // }),
          ...(elevate && {
            backgroundColor: (theme) => alpha(theme.palette.background.paper, darknessValue - 0),
            boxShadow: ((darknessValue) * 10).toFixed(0) < 8 ? ((darknessValue) * 10).toFixed(0) : 8
          })
        }}
      >

        <Stack
          direction="row"
          spacing={2}
          sx={{ height: TOP_NAV_HEIGHT }}
        >
          <Stack
            alignItems="center"
            direction="row"
            spacing={1}
            sx={{ flexGrow: 1, pl: '2%' }}
          >
            <Stack
              alignItems="center"
              component={RouterLink}
              direction="row"
              display="inline-flex"
              href={paths.index}
              spacing={1}
              sx={{ textDecoration: 'none' }}
            >
              <Box
                sx={{
                  ml: xlUp ? '50px' : mdUp ? '30px' : '15px',
                  display: 'inline-flex',
                  height: xlUp ? 45 : mdUp ? 40 : 30,
                  width: xlUp ? 216 : mdUp ? 192 : 144
                }}
              >
                <Logo />
                {/* {xlUp ? 'xl' : lgUp ? 'lg' : mdUp ? 'md' : smUp ? 'sm' : 'xs'} */}
              </Box>
            </Stack>
          </Stack>
          {lgUp && (
            <Stack
              alignItems="center"
              direction="row"
              spacing={2}
            >
              <Box
                component="nav"

                sx={{
                  height: '100%',
                  mr: xlUp ? '160px' : '130px',
                  mt: xlUp ? `${20 - (darknessValue * 20)}px` : `${15 - (darknessValue * 15)}px`
                }}
              >
                <Stack
                  component="ul"
                  alignItems="center"
                  justifyContent="center"
                  direction="row"
                  spacing={2}
                  sx={{
                    height: '100%',
                    listStyle: 'none',
                    m: 0,
                    p: 0
                  }}
                >
                  <>
                    {items.map((item) => {
                      const checkPath = !!(item.path && pathname);
                      const partialMatch = checkPath ? pathname.includes(item.path) : false;
                      const exactMatch = checkPath ? pathname === item.path : false;
                      const active = item.popover ? partialMatch : exactMatch;
                      return (
                        <TopNavItem
                          active={active}
                          external={item.external}
                          key={item.title}
                          path={item.path}
                          popover={item.popover}
                          title={item.title}
                        />
                      );
                    })}
                  </>
                </Stack>
              </Box>
            </Stack>
          )}

          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            sx={{
              flexGrow: 1,
              pr: '2%'
            }}
          >


            {lgUp && (<Button
              disableElevation
              sx={{
                borderRadius: '25px',
                mr: '20px',
                fontFamily: 'Poppins',
                fontWeight: 400,
                fontSize: xlUp ? '18px' : '16px',
                lineHeight: xlUp ? '26px' : '24px',
                color: '#FFFFF9',
                width: xlUp ? '160px' : '149px',
                height: xlUp ? '50px' : '48px',
              }}
              variant="contained"
            >
              Contact Us
            </Button>)}

            {!lgUp && (
              <ButtonBase
                onClick={onMobileNavOpen}
                sx={{
                  backgroundColor: 'primary.main',
                  borderRadius: '50%',
                  color: 'primary.contrastText',
                  p: '10px',
                  width: mdUp ? '50px' : '40px',
                  height: mdUp ? '50px' : '40px',
                }}
              >
                <SvgIcon
                  fontSize={mdUp ? "medium" : "small"}
                >
                  <Menu01Icon />
                </SvgIcon>
              </ButtonBase>
            )}
          </Stack>

        </Stack>

      </Container>
    </Box>

  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func
};
