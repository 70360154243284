
import { Box, Container, Stack, Typography, useMediaQuery, ButtonBase, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';


import ToDownArrowIcon from 'src/icons/leanworks/to-down-arrow-icon';


export const HomeHero = () => {
  const theme = useTheme();
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const xlUp = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  return (

    <Box
      sx={{
        height: xlUp ? '1750px' : '1300px'
      }}
    >
      <Grid container
        spacing={0}
      // sx={{ height: '700px' }}
      >
        <Grid item
          xs={4}>
          <Box
            sx={{
              transform: xlUp ? 'translate(50px, 50px)' : mdUp ? 'translate(30px, 30px)' : 'translate(15px, 15px)',
              background: (theme) => theme.palette.background.paper,
              borderRadius: '80px 0px 0px 80px',
              width: '100%',
              height: xlUp ? '860px' : '576px'
            }}
          >
          </Box>
        </Grid>
        <Grid item
          xs={4}>

          <Box
            sx={{
              backgroundRepeat: 'no-repeat',
              transform: xlUp ? 'translate(0px, 50px)' : mdUp ? 'translate(0px, 30px)' : 'translate(0px, 15px)',
              backgroundPosition: 'top center',
              //   backgroundSize: 'cover',
              backgroundImage: xlUp ? `url("/assets/bg-hero-base-xl-${theme.palette.mode}.svg")` : mdUp ? `url("/assets/bg-hero-base-md-${theme.palette.mode}.svg")` : `url("/assets/bg-hero-base-md.svg")`,
              margin: 'auto',
              width: '100%',
              height: xlUp ? '860px' : '576px'
            }}
          >

          </Box>

        </Grid>
        <Grid item
          xs={4}>
          <Box
            sx={{
              transform: xlUp ? 'translate(-50px, 50px)' : mdUp ? 'translate(-30px, 30px)' : 'translate(-15px, 15px)',
              background: (theme) => theme.palette.background.paper,
              borderRadius: '0px 80px 0px 0px',
              width: '100%',
              height: xlUp ? '640px' : '429px'
            }}
          >
          </Box>
        </Grid>
      </Grid>

      <Grid container
        spacing={0}
        sx={{ height: '0px' }}
      >
        <Grid item
          xs={1}></Grid>
        <Grid item
          xs={5}>
          <Box
            pl="5%"
            sx={{
              transform: xlUp ? 'translate(0px, -600px)' : mdUp ? 'translate(0px, -400px)' : 'translate(-15px, -420px)',

              width: xlUp ? '704px' : mdUp ? '582px' : '582px',
              height: xlUp ? '980px' : mdUp ? '696px' : '696px'
            }}
          >
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Typography
                sx={{
                  mb: xlUp ? 2 : 1,
                  fontFamily: 'Poppins',
                  fontWeight: 400,
                  fontSize: xlUp ? '60px' : mdUp ? '44px' : '44px',
                  lineHeight: xlUp ? '80px' : mdUp ? '64px' : '64px',
                }}
              >
                Let us worry about the&nbsp;
                <Typography
                  component="span"
                  color="primary.main"
                  variant="inherit"
                >
                  Patient Data
                </Typography>
                , you focus on Healthcare.
              </Typography>
              <Box
                maxWidth="400px"
              >
                <Typography
                  color="text.secondary"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: xlUp ? '14px' : mdUp ? '12px' : '12px',
                    fontWeight: 600,
                    lineHeight: xlUp ? '28px' : mdUp ? '24px' : '24px',
                    letterSpacing: '2px',
                    textTransform: 'uppercase'
                  }}
                >
                  We support hospitals, medical centers and healthcare organizations
                </Typography>
              </Box>
              <Box
                pt={xlUp ? "220px" : "75px"}
              >

                <Stack
                  alignItems="center"
                  direction="row"
                  flexWrap="wrap"
                  spacing={1}
                >
                  <Typography
                    color="text.primary"
                    variant="h6"
                    sx={{ fontWeight: 500 }}
                  >
                    Scroll down to learn more
                  </Typography>
                  <ButtonBase
                    sx={{
                      // backgroundColor: 'primary.main',
                      borderRadius: '50%',
                      // color: 'primary.contrastText',
                      // p: '10px'
                      height: '52px',
                      width: '52px'
                    }}
                  >
                    <ToDownArrowIcon />
                  </ButtonBase>
                </Stack>
              </Box>
            </Stack>

          </Box>

        </Grid>
        {mdUp && <Grid item
          xs={6}>
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <Box
              sx={{
                backgroundRepeat: 'no-repeat',
                transform: xlUp ? 'translate(0px, -850px)' : mdUp ? 'translate(0px, -576px)' : 'translate(0px, -591px)',
                backgroundPosition: 'top center',
                backgroundImage: xlUp ? `url("/assets/bg-hero-shape-xl.svg")` : mdUp ? `url("/assets/bg-hero-shape-md.svg")` : `url("/assets/bg-hero-shape-md.svg")`,
                width: xlUp ? '704px' : mdUp ? '482px' : '452px',
                height: xlUp ? '980px' : mdUp ? '696px' : '696px'
              }}
            >
            </Box>
          </Box>
        </Grid>}
      </Grid>
      {mdUp && <Grid container
        spacing={0}
        sx={{ height: '0px' }}
      >
        <Grid item
          xs={12}>

          <Box
            sx={{
              backgroundRepeat: 'no-repeat',
              transform: xlUp ? 'translate(0px, 288px)' : 'translate(0px, 240px)',
              backgroundPosition: 'top center',
              backgroundImage: xlUp ? `url("/assets/bg-text-hero-xl.svg")` : mdUp ? `url("/assets/bg-text-hero-md.svg")` : `url("/assets/bg-text-hero-sm.svg")`,
              margin: 'auto',
              width: xlUp ? '1040px' : '828px',
              height: xlUp ? '288px' : '240px'
            }}
          >
            <Typography
              fontFamily="Poppins"
              fontSize={xlUp ? '34px' : "26px"}
              fontWeight="400"
              lineHeight={xlUp ? '48px' : "40px"}
              letterSpacing="0px"
              textAlign="center"
              color="#142F43"
            >
              We support hospitals, medical centers and healthcare organizations on the path to operational excellence through continuous process improvement strategies, clinical data governance and digital transformation, focusing on our Clients to improve service quality, reduce operational losses, decrease errors in care and improve Patient safety.
            </Typography>
          </Box>
        </Grid>
      </Grid>}
    </Box>
  );
};
