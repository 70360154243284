const MenuDownIcon = (props) => (
    <svg width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path d="M13.7092 0.329895C13.6163 0.225362 13.5057 0.142392 13.3839 0.0857716C13.262 0.0291509 13.1313 0 12.9993 0C12.8673 0 12.7366 0.0291509 12.6148 0.0857716C12.4929 0.142392 12.3824 0.225362 12.2894 0.329895L7.70992 5.43783C7.61697 5.54236 7.50638 5.62533 7.38453 5.68195C7.26269 5.73857 7.132 5.76772 7 5.76772C6.868 5.76772 6.73731 5.73857 6.61547 5.68195C6.49362 5.62533 6.38303 5.54236 6.29008 5.43783L1.7106 0.329895C1.61765 0.225362 1.50706 0.142392 1.38521 0.0857716C1.26337 0.0291509 1.13268 0 1.00068 0C0.868682 0 0.737991 0.0291509 0.616146 0.0857716C0.4943 0.142392 0.383712 0.225362 0.29076 0.329895C0.10453 0.538854 0 0.821521 0 1.11616C0 1.4108 0.10453 1.69346 0.29076 1.90242L4.88024 7.02151C5.44268 7.64807 6.20509 8 7 8C7.79491 8 8.55732 7.64807 9.11976 7.02151L13.7092 1.90242C13.8955 1.69346 14 1.4108 14 1.11616C14 0.821521 13.8955 0.538854 13.7092 0.329895V0.329895Z"
            fill="currentcolor" />
    </svg>
);

export default MenuDownIcon;