
import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  ButtonBase,
  Grid
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ToRightArrowIcon from 'src/icons/leanworks/to-right-arrow';


export const HomeOurPhilosophy = () => {
  const theme = useTheme();
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const xlUp = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  return (
    <Box >


      <Grid container
        spacing={0}
      // sx={{ height: '200px' }}
      >
        <Grid item
          xs={8}>

          <Box
            sx={{
              transform: 'translate(0px, 0px)',
              background: theme.palette.mode === 'dark' ? 'linear-gradient(180deg, #00BDBF 0%, #02778F 100%)' : '#FFFFF9',
              boxShadow: theme.palette.mode === 'dark' ? 'inset -30px 0px 90px rgba(4, 31, 47, 0.25)' : '',
              borderRadius: '0px 80px 80px 0px',
              width: '100%',
              height: '690px'
            }}
          >


          </Box>

        </Grid>
        <Grid item
          xs={4}>
          <Box
            sx={{
              backgroundRepeat: 'no-repeat',
              transform: 'translate(-172px, 270px)',
              backgroundPosition: 'top center',
              backgroundImage: xlUp ? `url("/assets/bg-our-philosophy-md.svg")` : mdUp ? `url("/assets/bg-our-philosophy-md.svg")` : `url("/assets/bg-our-philosophy-md.svg")`,
              pt: '80px',
              pl: '0px',
              width: '600px',
              height: '950px'
            }}
          >
          </Box>
        </Grid>
      </Grid>
      <Container maxWidth={xlUp ? "xl" : "lg"}>

        <Box
          height="0px"

          sx={{
            pl: '20px',
            transform: 'translate(0px, -900px)',
          }}
        >
          <Grid container
            spacing={0}
          // sx={{ height: '200px' }}
          >
            <Grid item
              xs={8}>

              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Typography
                  color="text.secondary"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '22px',
                    letterSpacing: '2px',
                    textTransform: 'uppercase'
                  }}
                >
                  Services
                </Typography>
                <Typography
                  color="text.primary"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: '36px',
                    fontWeight: '400',
                    lineHeight: '46px'
                  }}
                >
                  Our SAAS
                </Typography>
                <Box
                  pr="140px"
                >

                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                  >


                    <Typography
                      variant="body1"
                      sx={{
                        opacity: theme.palette.mode === 'dark' ? '0.75' : '1'
                      }}
                    >
                      {`The Software as a Service (SAAS) for patient clinical information management is an innovative and highly beneficial solution for healthcare institutions. This type of platform offers a wide range of functionalities designed specifically to meet the needs of healthcare professionals and optimize the management of clinical information.`}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        opacity: theme.palette.mode === 'dark' ? '0.75' : '1'
                      }}
                    >
                      {`One of the main advantages of this SAAS is its ease of use. The intuitive and user-friendly interface allows users to navigate and quickly access patient data, simplifying the process of recording and maintaining clinical information. This improves efficiency and reduces administrative workload, allowing healthcare professionals to spend more time providing quality care.`}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        opacity: theme.palette.mode === 'dark' ? '0.75' : '1'
                      }}
                    >
                      {`Furthermore, the software offers a wide range of features that enhance the management of clinical information. It enables the secure recording and storage of medical histories, diagnoses, treatments, and any other relevant patient information. It also facilitates appointment scheduling, medication tracking, and the generation of customized reports, contributing to better clinical decision-making.`}
                    </Typography>
                    <Box
                      pt="5px"
                    >
                      {/* <Stack
                        alignItems="center"
                        direction="row"
                        flexWrap="wrap"
                        spacing={1}
                      >
                        <Typography
                          color="text.primary"
                          sx={{
                            fontWeight: 500,
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            textTransform: 'uppercase',
                            letterSpacing: '2px',
                            textDecorationLine: 'underline'
                          }}
                        >
                          Read more
                        </Typography>
                        <ButtonBase
                          sx={{
                            // backgroundColor: 'primary.main',
                            borderRadius: '50%',
                            // color: 'primary.contrastText',
                            // p: '10px'
                            height: '52px',
                            width: '52px'
                          }}
                        >
                          <ToRightArrowIcon />
                        </ButtonBase>
                      </Stack> */}
                    </Box>
                  </Stack>
                </Box>

              </Stack>

            </Grid>
          </Grid>
        </Box>
      </Container>

    </Box>

  );
};
