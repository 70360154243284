import { Seo } from 'src/components/seo';
import { usePageView } from 'src/hooks/use-page-view';
import { HomeContactUs } from 'src/sections/home/home-contact-us';
//import { HomeCta } from 'src/sections/home/home-cta';
//import { HomeFaqs } from 'src/sections/home/home-faqs';
//import { HomeFeatures } from 'src/sections/home/home-features';
import { HomeHero } from 'src/sections/home/home-hero';
import { HomeOurPhilosophy } from 'src/sections/home/home-our-philosophy';
//import { HomeReviews } from 'src/sections/home/home-reviews';
import { HomeWeProvide } from 'src/sections/home/home-we-provide';

const Page = () => {
  usePageView();

  return (
    <>
      <Seo />
      <main>
        <HomeHero />
        <HomeOurPhilosophy />
        {/* <HomeWeProvide /> */}
        <HomeContactUs />
        {/* <HomeFeatures />
        <HomeReviews />
        <HomeCta /> 
        <HomeFaqs />*/}
      </main>
    </>
  );
};

export default Page;
